import logo from './logo.svg';
import './App.css';
import './index.css';
import { useState } from 'react';
import TracklistGenerator from './TracklistGenerator';

const App = () => {
  return <TracklistGenerator />;
};

export default App;
